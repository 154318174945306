import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import moment from "moment-timezone";
import { Button, Input, Modal, Select, TextArea } from "../../../components";
import { Class, UserAccount, UserBootcamps } from "../../../types/interfaces";
import {
  classStatus,
  classStructure,
  userBootcampsStatus,
  userStatus,
} from "../../../types/enumerators";
import { setLoad } from "../../../store/actions/configurationsActions";
import {
  filterClass,
  getClassVacancyByClassId,
  getClassVacancySubscriptionByUserIdAndClassId,
  patchUser,
  updateClassVacancySubscriptionById,
} from "../../../services/functions";
import {
  ReasonsDisapproved,
  ReasonsGeneral,
} from "../../../types/constants/User";
import { RootState } from "../../../store/reducers";
import "../style.scss";

interface User {
  userId: string;
  account: UserAccount;
  bootcamps: UserBootcamps[];
}

interface Props {
  open: boolean;
  close: () => void;
  users: User[];
  classId: string;
  title: string;
  status: string;
  reload: () => void;
}

const ToGeneralStatus = ({
  open,
  close,
  users,
  classId,
  title,
  status,
  reload,
}: Props): JSX.Element => {
  const [classList, setClassList] = useState<Class[]>();
  const [classSelected, setClassSelected] = useState<string>();
  const [statusReasons, setStatusReasons] = useState<string>();
  const [statusReasonsDescription, setStatusReasonsDescription] =
    useState<string>();
  const [week, setWeek] = useState<number>();
  const [statusComments, setStatusComments] = useState<string>();
  const usr = useSelector((state: RootState) => state.userState);

  const dispatch = useDispatch();

  const getClassList = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    const response = await filterClass({
      status: classStatus.ACTIVE,
      classStructure: [
        classStructure.FOUR_WEEKS,
        classStructure.SIX_WEEKS,
        classStructure.EIGHT_WEEKS,
        classStructure.TWELVE_WEEKS,
        classStructure.FOURTEEN_WEEKS,
        classStructure.EIGHTEEN_WEEKS,
      ],
    });
    const allClasses = response?.data;

    const classes = allClasses
      ?.filter((item: { _id: string; code: string }) => item?._id !== classId)
      ?.map((key: { _id: string; code: string }) => ({
        _id: key?._id,
        code: key?.code,
      }));

    setClassList(classes);
    dispatch(setLoad(false) as unknown as Action);
  }, [classId, dispatch]);

  useEffect(() => {
    if (status === userBootcampsStatus.CHANGED && !classList) getClassList();
  }, [classList, getClassList, status]);

  const Reasons = useMemo(() => {
    if (status === userBootcampsStatus.DISAPPROVED) return ReasonsDisapproved;
    return ReasonsGeneral;
  }, [status]);

  const onClose = useCallback(() => {
    setClassSelected(undefined);
    setStatusReasons(undefined);
    setStatusReasonsDescription(undefined);
    setWeek(undefined);
    setStatusComments(undefined);
    close();
  }, [close]);

  const onChangeStatus = useCallback(
    async (user) => {
      dispatch(setLoad(true) as unknown as Action);

      const account = { ...user?.account };
      const bootcamps = [...(user?.bootcamps ?? [])];
      const i = bootcamps?.findIndex((item) => item?.classId === classId) ?? -1;

      if (i > -1) {
        const bootcamp = {
          ...bootcamps[i],
          status,
          statusReasons,
          statusReasonsDescription,
          week,
        };

        if (statusComments)
          bootcamp.comments = [
            ...(bootcamp?.comments ?? []),
            {
              text: statusComments,
              userId: usr?._id,
              date: moment().tz("America/Sao_Paulo").toISOString(),
              type: "STATUS",
            },
          ];

        bootcamps[i] = bootcamp;

        const newBootcamp = {} as {
          classId: string;
          status: keyof typeof userBootcampsStatus;
        };

        if (status === userBootcampsStatus.CHANGED) {
          newBootcamp.classId = classSelected ?? "";
          newBootcamp.status = userBootcampsStatus.IN_PROCESS;
          bootcamps?.push(newBootcamp);

          const subscription =
            await getClassVacancySubscriptionByUserIdAndClassId(
              user?._id,
              user?.bootcamps?.[i]?.classId,
            );
          const classVacancy = await getClassVacancyByClassId(
            classSelected ?? "",
          );

          if (subscription && subscription?.length) {
            const subs = subscription[0];
            await updateClassVacancySubscriptionById(subs?._id, {
              ...subs,
              ticket: "BOOTCAMPS_CHANGED",
              classVacancyId: classVacancy?._id,
              oldClassVacancyId: subs?.classVacancyId,
            });
          }
        }

        if (
          (status === userBootcampsStatus.DISAPPROVED ||
            status === userBootcampsStatus.GIVE_UP ||
            status === userBootcampsStatus.STOPPED ||
            status === userBootcampsStatus.REPLACED) &&
          user?.account?.status
        )
          account.status = userStatus.DISABLED;

        await patchUser(user?.userId ?? "", { account, bootcamps });
      }

      reload();
      onClose();
      dispatch(setLoad(false) as unknown as Action);
    },
    [
      dispatch,
      reload,
      onClose,
      classId,
      status,
      statusReasons,
      statusReasonsDescription,
      week,
      statusComments,
      usr?._id,
      classSelected,
    ],
  );

  const onAllChangeStatus = useCallback(() => {
    users?.forEach((user) => onChangeStatus(user));
  }, [users, onChangeStatus]);

  return (
    <Modal headerAction open={open} onClose={onClose} textHeader={title}>
      {status === userBootcampsStatus.CHANGED && (
        <Select
          id="class"
          className="margin-bottom-24"
          label="Turma*"
          options={
            classList?.map((item) => ({
              value: item?._id ?? "",
              label: item?.code ?? "",
            })) ?? []
          }
          value={classSelected ?? ""}
          onChange={(e) => setClassSelected(e.target.value)}
        />
      )}
      <Select
        id="justify"
        label="Motivo*"
        options={Object.keys(Reasons)?.map((key) => ({
          value: key,
          label: Reasons[key as keyof typeof Reasons],
        }))}
        value={statusReasons ?? ""}
        onChange={(e) => setStatusReasons(e.target.value)}
      />
      {statusReasons === "OTHERS" && (
        <Input
          id="statusReasonsDescription"
          label="Descreva"
          value={statusReasonsDescription ?? ""}
          maxLength={200}
          onChange={(e) => setStatusReasonsDescription(e.target.value)}
          className="margin-top-32"
        />
      )}
      <Input
        id="week"
        label="Semana*"
        type="number"
        value={week ?? ""}
        onChange={(e) => setWeek(Number(e.target.value))}
        className="margin-top-32"
      />
      <TextArea
        id="justifyDescription"
        label="Comentário"
        value={statusComments ?? ""}
        maxLength={200}
        onChange={(e) => setStatusComments(e.target.value)}
        className="margin-top-32"
      />
      <div className="margin-top-32 admin-subscriptions-modal-buttons">
        <Button
          text="Cancelar"
          onClick={onClose}
          outline
          appearance="secondary"
        />
        <Button
          text="Salvar"
          className="margin-left-32 margin-left-mobile"
          onClick={onAllChangeStatus}
          disabled={!statusReasons || !week}
        />
      </div>
    </Modal>
  );
};

export default memo(ToGeneralStatus);
