/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ChangeEvent,
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import {
  FacebookProfile,
  GitHubProfile,
  InstagramProfile,
  Linkedin,
  TikTokProfile,
  TwitterProfile,
} from "../../../assets/customIcons";
import { RootState } from "../../store/reducers";
import { sociais } from "../../types/constants/Sociais";
import {
  companySocialMedias,
  userSocialMediaType,
} from "../../types/enumerators";
import { hasCorporateRole } from "../../utils";
import Input from "../Input";

interface Props {
  setIsValid?: Dispatch<SetStateAction<boolean>>;
  setIsOptional?: Dispatch<SetStateAction<boolean>>;
  socialMedias: any;
  updateSocialMedia: any;
  showText?: boolean;
  className?: string;
}

const EditSocialMedias = ({
  setIsValid,
  setIsOptional,
  socialMedias,
  updateSocialMedia,
  showText,
  className,
}: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const [socialMedia, setSocialMedia] = useState(socialMedias ?? []);
  const user = useSelector((state: RootState) => state.userState);
  const isCompany = hasCorporateRole(user);

  const handleOnFocus = useCallback(
    (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      type:
        | "LINKEDIN"
        | "LINKEDIN_COMPANY"
        | "INSTAGRAM"
        | "FACEBOOK"
        | "TIKTOK"
        | "TWITTER"
        | "GITHUB",
    ) => {
      if (!sociais[type].baseRegex.test(e.target.value)) {
        let newSocialMedias = [...socialMedia];

        if (newSocialMedias && newSocialMedias?.length) {
          const index = newSocialMedias?.findIndex(
            (item: { type: string }) => item.type === type,
          );
          if (index !== -1) newSocialMedias[index].url = sociais[type].baseURL;
          else newSocialMedias?.push({ type, url: sociais[type].baseURL });
        } else newSocialMedias = [{ type, url: sociais[type].baseURL }];

        setSocialMedia(newSocialMedias);
      }
    },
    [socialMedia],
  );

  const handleOnBlur = useCallback(
    (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      type:
        | "LINKEDIN"
        | "LINKEDIN_COMPANY"
        | "INSTAGRAM"
        | "FACEBOOK"
        | "TIKTOK"
        | "TWITTER"
        | "GITHUB",
    ) => {
      const value = e.target.value.replace(sociais[type].baseURL, "");
      const newSocialMedias = [...socialMedia];

      if (value === "") {
        const index = newSocialMedias?.findIndex(
          (item: { type: string }) => item.type === type,
        );
        newSocialMedias?.splice(index, 1);
        setSocialMedia(newSocialMedias);
      }

      if (socialMedia?.length) {
        if (setIsValid) setIsValid(true);
        if (setIsOptional) setIsOptional(false);
        updateSocialMedia(newSocialMedias);
      } else {
        if (setIsValid) setIsValid(false);
        if (setIsOptional) setIsOptional(true);
      }
    },
    [setIsOptional, setIsValid, socialMedia, updateSocialMedia],
  );

  const handleChange = useCallback(
    (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      type:
        | "LINKEDIN"
        | "LINKEDIN_COMPANY"
        | "INSTAGRAM"
        | "FACEBOOK"
        | "TIKTOK"
        | "TWITTER"
        | "GITHUB",
    ) => {
      let { value } = e.target;

      if (value === "" || sociais[type].baseRegex.test(value)) {
        let newSocialMedias = [...socialMedia];

        const rg = new RegExp(sociais[type].baseURL, "g");
        const count = (value.match(rg) || []).length;
        if (count > 1)
          value = e.target.value.replace(sociais[type].baseURL, "");

        if (newSocialMedias && newSocialMedias?.length) {
          const index = newSocialMedias?.findIndex(
            (item: { type: string }) => item.type === type,
          );
          if (index !== -1) newSocialMedias[index].url = value;
          else newSocialMedias?.push({ type, url: value });
        } else newSocialMedias = [{ type, url: value }];

        setSocialMedia(newSocialMedias);
      }
    },
    [socialMedia],
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className={`grid width-100-responsive flex-col-mobile gap-20-mobile ${
        className || ""
      }`}
    >
      {showText && (
        <p className="title-input grid-row-1">
          Nada melhor do que as redes sociais para fortalecer a comunidade de
          mulheres coders. Compartilhe as suas!
        </p>
      )}

      <div className="flex-row-center margin-top-24-mobile ">
        <Linkedin
          widthIcon={width < 991 ? 32 : 40}
          heightIcon={width < 991 ? 32 : 40}
        />
        <Input
          label="Linkedin"
          type="text"
          value={
            isCompany
              ? socialMedia?.find(
                  (item: { type: string }) =>
                    item.type === companySocialMedias.LINKEDIN_COMPANY,
                )?.url ?? ""
              : socialMedia?.find(
                  (item: { type: string }) =>
                    item.type === userSocialMediaType.LINKEDIN,
                )?.url ?? ""
          }
          onChange={(e) =>
            handleChange(
              e,
              isCompany
                ? companySocialMedias.LINKEDIN_COMPANY
                : userSocialMediaType.LINKEDIN,
            )
          }
          onBlur={(e) =>
            handleOnBlur(
              e,
              isCompany
                ? companySocialMedias.LINKEDIN_COMPANY
                : userSocialMediaType.LINKEDIN,
            )
          }
          onFocus={(e) =>
            handleOnFocus(
              e,
              isCompany
                ? companySocialMedias.LINKEDIN_COMPANY
                : userSocialMediaType.LINKEDIN,
            )
          }
          className="grid-row-2 margin-left-12 width-100-responsive min-width-350"
        />
      </div>
      <div className="flex-row-center margin-top-24-mobile">
        <InstagramProfile
          widthIcon={width < 991 ? 32 : 40}
          heightIcon={width < 991 ? 32 : 40}
        />
        <Input
          label="Instagram"
          type="text"
          className="grid-row-3 margin-left-12 width-100-responsive min-width-350"
          value={
            socialMedia?.find(
              (item: { type: string }) =>
                item.type === userSocialMediaType.INSTAGRAM,
            )?.url ?? ""
          }
          onChange={(e) => handleChange(e, "INSTAGRAM")}
          onBlur={(e) => handleOnBlur(e, "INSTAGRAM")}
          onFocus={(e) => handleOnFocus(e, "INSTAGRAM")}
        />
      </div>

      <div className="flex-row-center margin-top-24-mobile">
        <FacebookProfile
          widthIcon={width < 991 ? 32 : 40}
          heightIcon={width < 991 ? 32 : 40}
        />
        <Input
          label="Facebook"
          type="text"
          value={
            socialMedia?.find(
              (item: { type: string }) => item.type === userSocialMediaType.FACEBOOK,
            )?.url ?? ""
          }
          onChange={(e) => handleChange(e, "FACEBOOK")}
          onBlur={(e) => handleOnBlur(e, "FACEBOOK")}
          onFocus={(e) => handleOnFocus(e, "FACEBOOK")}
          className="grid-row-4 margin-left-12 width-100-responsive min-width-350"
        />
      </div>
      <div className="flex-row-center margin-top-24-mobile">
        <TikTokProfile
          widthIcon={width < 991 ? 32 : 40}
          heightIcon={width < 991 ? 32 : 40}
        />
        <Input
          label="TikTok"
          type="text"
          value={
            socialMedia?.find(
              (item: { type: string }) =>
                item.type === userSocialMediaType.TIKTOK,
            )?.url ?? ""
          }
          onChange={(e) => handleChange(e, "TIKTOK")}
          onBlur={(e) => handleOnBlur(e, "TIKTOK")}
          onFocus={(e) => handleOnFocus(e, "TIKTOK")}
          className="grid-row-5 margin-left-12 width-100-responsive min-width-350"
        />
      </div>

      <div className="flex-row-center margin-top-24-mobile">
        <TwitterProfile
          widthIcon={width < 991 ? 32 : 40}
          heightIcon={width < 991 ? 32 : 40}
        />
        <Input
          label="Twitter"
          type="text"
          value={
            socialMedia?.find(
              (item: { type: string }) => item.type === "TWITTER",
            )?.url ?? ""
          }
          onChange={(e) => handleChange(e, "TWITTER")}
          onBlur={(e) => handleOnBlur(e, "TWITTER")}
          onFocus={(e) => handleOnFocus(e, "TWITTER")}
          className="grid-row-6 margin-left-12 width-100-responsive min-width-350"
        />
      </div>
      <div className="flex-row-center margin-top-24-mobile">
        <GitHubProfile
          widthIcon={width < 991 ? 32 : 40}
          heightIcon={width < 991 ? 32 : 40}
        />
        <Input
          label="GitHub"
          type="text"
          value={
            socialMedia?.find(
              (item: { type: string }) => item.type === "GITHUB",
            )?.url ?? ""
          }
          onChange={(e) => handleChange(e, "GITHUB")}
          onBlur={(e) => handleOnBlur(e, "GITHUB")}
          onFocus={(e) => handleOnFocus(e, "GITHUB")}
          className="grid-row-7 margin-left-12 width-100-responsive min-width-350"
        />
      </div>
    </div>
  );
};

export default memo(EditSocialMedias);
