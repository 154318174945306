/* eslint-disable no-prototype-builtins */
import React, { memo, useState, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { toast } from "react-toastify";
import { ClassVacancy, Class } from "reprograma-types/dist/interface";
import { RootState } from "../../store/reducers";
import { Layout, CardStepsCourse, CardSteps } from "../../components";
import {
  getActiveUserClassVacancySubscription,
  getClassVacancyById,
  getClassById,
} from "../../services/functions";
import { setCourseSubscription } from "../../store/actions/courseActions";
import "./style.scss";
import Loading from "../Loading";
import {
  ChoiceClassWorkshop,
  SuccessSendWorkshop,
  ConfirmationWorkshop,
  NextStages,
  Form,
  Media,
  SuccessSendSubstep,
  FinishedWorkshopError,
} from "./Steps";
import {
  ClassVacancyStages,
  ClassVacancySubscription,
  ClassVacancySubscriptionStage,
  User,
} from "../../types/interfaces";
import { classVacancyStatus, classVacancyType } from "../../types/enumerators";

const CourseWorkshop = (): JSX.Element => {
  const user: User = useSelector((state: RootState) => state.userState);
  const subscription: ClassVacancySubscription = useSelector(
    (state: RootState) => state.courseState,
  );
  const [classVacancy, setClassVacancy] = useState<ClassVacancy>();
  const [currentStageWorkshop, setCurrentStageWorkshop] =
    useState<ClassVacancySubscriptionStage>();

  const [width, setWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();

  const smallScreen = width < 990;

  const [firstSubstepEnable, setFirstSubstepEnable] = useState(false);
  const [workshopClass, setWorkshopClass] = useState<Class>();

  const getSubscription = useCallback(async () => {
    const subscriptions = await getActiveUserClassVacancySubscription(
      user._id ?? "",
    );
    if (subscriptions?.data?.length)
      dispatch(
        setCourseSubscription(subscriptions?.data?.[0]) as unknown as Action,
      );
  }, [dispatch, user._id]);

  const getClassVacancy = useCallback(async () => {
    const response = await getClassVacancyById(subscription?.classVacancyId);
    if (response?.data) setClassVacancy(response?.data);
  }, [subscription?.classVacancyId]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!subscription?._id) getSubscription();
  }, [subscription?._id, getSubscription]);

  useEffect(() => {
    if (!classVacancy && !!subscription?._id) getClassVacancy();
  }, [classVacancy, subscription?._id, getClassVacancy]);

  const findStageWorkshop = useMemo(
    () =>
      classVacancy?.stages?.find(
        (item) => item.type === classVacancyType.WORKSHOP,
      ) ?? ({} as ClassVacancyStages),
    [classVacancy?.stages],
  );

  const getCurrentStageWorkshop = useCallback(() => {
    const stageWorkshop = subscription?.stages?.find(
      (item: { stageId?: string }) =>
        item?.stageId === findStageWorkshop?.stage_id,
    );
    setCurrentStageWorkshop(stageWorkshop);
  }, [subscription, findStageWorkshop]);

  useEffect(() => {
    if (classVacancy?._id) getCurrentStageWorkshop();
  }, [classVacancy?._id, getCurrentStageWorkshop]);

  const getClass = useCallback(async () => {
    const response = await getClassById(
      currentStageWorkshop?.workshopClass ?? "",
    );
    if (response?.data) setWorkshopClass(response?.data);
  }, [currentStageWorkshop]);

  useEffect(() => {
    if (currentStageWorkshop) getClass();
  }, [currentStageWorkshop, getClass]);

  const finishedClassWorkshop = useMemo(
    () => workshopClass?.status === classVacancyStatus.FINISHED,
    [workshopClass?.status],
  );

  const pagesSubsteps = useMemo(
    () => [
      {
        label: "behavior",
        type: "EVALUATION_BEHAVIOR",
        input: "hasEvaluationBehavior",
        component: (
          <Media
            userId={user?._id}
            subscription={subscription}
            findStageWorkshop={findStageWorkshop as ClassVacancyStages}
            currentStageWorkshop={
              currentStageWorkshop as ClassVacancySubscriptionStage
            }
            smallScreen={smallScreen}
            prop="behavior"
            text="Avaliação Comportamental"
          />
        ),
        text: "Avaliação Comportamental",
      },
      {
        label: "form",
        type: "DIVERSITY_FORM",
        input: "hasDiversityForm",
        component: (
          <Form
            userId={user?._id}
            subscription={subscription}
            findStageWorkshop={findStageWorkshop as ClassVacancyStages}
            currentStageWorkshop={
              currentStageWorkshop as ClassVacancySubscriptionStage
            }
            prop="form"
            text="Formulário de Diversidade"
          />
        ),
        text: "Formulário de Diversidade",
      },
      {
        label: "media",
        type: "TECHNICAL_TEST",
        input: "hasTechnicalTest",
        component: (
          <Media
            userId={user?._id}
            subscription={subscription}
            findStageWorkshop={findStageWorkshop as ClassVacancyStages}
            currentStageWorkshop={
              currentStageWorkshop as ClassVacancySubscriptionStage
            }
            smallScreen={smallScreen}
            prop="media"
            text="Desafio Técninco"
          />
        ),
        text: "Desafio Técninco",
      },
      {
        label: "technicalForm",
        type: "TECHNICAL_TEST_FORM",
        input: "hasTechnicalTestForm",
        component: (
          <Form
            userId={user?._id}
            subscription={subscription}
            findStageWorkshop={findStageWorkshop as ClassVacancyStages}
            currentStageWorkshop={
              currentStageWorkshop as ClassVacancySubscriptionStage
            }
            prop="technicalForm"
            text="Desafio Técninco"
          />
        ),
        text: "Desafio Técninco",
      },
    ],
    [
      currentStageWorkshop,
      findStageWorkshop,
      smallScreen,
      subscription,
      user?._id,
    ],
  );

  const listOfSubsteps = useMemo(
    () =>
      findStageWorkshop
        ? pagesSubsteps
            ?.filter(
              (item) =>
                findStageWorkshop?.[item.input as keyof ClassVacancyStages],
            )
            ?.map((item) => item?.text)
        : undefined,
    [findStageWorkshop, pagesSubsteps],
  );

  const sendPageSubsteps = useMemo(() => {
    const ebableType = workshopClass?.steps?.find(
      (step: { type?: string; enable?: boolean }) => step?.enable,
    )?.type;

    const step:
      | { label: string; type: string; component: JSX.Element; text: string }
      | undefined = pagesSubsteps.find((item) => item.type === ebableType);
    const label: string = step?.label ?? "";

    if (
      currentStageWorkshop &&
      currentStageWorkshop?.[label as keyof ClassVacancySubscriptionStage]
    )
      return <SuccessSendSubstep substepText={step?.text} />;

    return step?.component;
  }, [currentStageWorkshop, workshopClass, pagesSubsteps]);

  const onClickFunction = useCallback(() => {
    if (sendPageSubsteps) setFirstSubstepEnable(true);
    else toast.error("Ocorreu um erro. Subetapa desabilitada");
  }, [sendPageSubsteps]);

  const hasAllSubsteps = useMemo(
    () =>
      currentStageWorkshop &&
      !Object.hasOwn(currentStageWorkshop, "form") &&
      !Object.hasOwn(currentStageWorkshop, "media") &&
      !Object.hasOwn(currentStageWorkshop, "behavior") &&
      !Object.hasOwn(currentStageWorkshop, "technicalForm"),
    [currentStageWorkshop],
  );

  const renderPagesWorkshop = useMemo(() => {
    if (
      currentStageWorkshop &&
      !Object.hasOwn(currentStageWorkshop, "workshopClass")
    )
      return (
        <ChoiceClassWorkshop
          classVacancy={classVacancy as ClassVacancy}
          user={user}
          subscription={subscription}
          findStageWorkshop={findStageWorkshop as ClassVacancyStages}
        />
      );

    if (
      currentStageWorkshop &&
      Object.hasOwn(currentStageWorkshop, "workshopClass") &&
      workshopClass &&
      Object.hasOwn(workshopClass, "confirmationWorkshop") &&
      !workshopClass?.isActive &&
      !finishedClassWorkshop
    )
      return <ConfirmationWorkshop workshopClass={workshopClass} />;

    if (
      workshopClass?.isActive &&
      !firstSubstepEnable &&
      hasAllSubsteps &&
      !finishedClassWorkshop
    )
      return (
        <NextStages
          handleClickStage={() => onClickFunction()}
          listOfSubsteps={listOfSubsteps}
        />
      );

    if ((workshopClass?.isActive && sendPageSubsteps) || firstSubstepEnable)
      return sendPageSubsteps;

    if (!workshopClass?.isActive && finishedClassWorkshop)
      return <FinishedWorkshopError />;

    return (
      <SuccessSendWorkshop
        choiceConfirmed={
          currentStageWorkshop && Object.hasOwn(currentStageWorkshop, "options")
        }
      />
    );
  }, [
    currentStageWorkshop,
    classVacancy,
    user,
    subscription,
    findStageWorkshop,
    workshopClass,
    finishedClassWorkshop,
    firstSubstepEnable,
    hasAllSubsteps,
    listOfSubsteps,
    sendPageSubsteps,
    onClickFunction,
  ]);

  const itemsMenu:
    | { icon?: JSX.Element; text?: string; id: number }[]
    | undefined = useMemo(
    () =>
      classVacancy?.stages?.map(({ order, title }) => ({
        text: title,
        id: order + 1,
      })),
    [classVacancy?.stages],
  );

  const activeStage = useMemo(
    () =>
      classVacancy?.stages?.find(
        (stage) => stage.status === classVacancyStatus.ACTIVE,
      )?.order,
    [classVacancy?.stages],
  );

  const steps = useMemo(
    () => ({
      page: 1,
      maxPageVisited: activeStage,
      maxMenuVisited: activeStage,
    }),
    [activeStage],
  );

  return classVacancy === undefined ? (
    <Loading />
  ) : (
    <Layout
      headerCourse
      exit
      className="padding-bottom-40 grid-responsive course course-workshop"
    >
      {width > 990 ? (
        <CardStepsCourse
          info={workshopClass?.isActive}
          stages={classVacancy?.stages}
          listOfSubsteps={listOfSubsteps}
        />
      ) : (
        <div>
          <CardSteps
            smallScreen
            className="card-steps-register step-MuiPaper-root"
            orientation="horizontal"
            itemsMenu={itemsMenu ?? []}
            steps={steps}
          />
        </div>
      )}
      {renderPagesWorkshop}
    </Layout>
  );
};
export default memo(CourseWorkshop);
