import React, { memo, useCallback, useMemo, useState } from "react";
import moment from "moment";
import { Card } from "../../components";
import { getTextDiffDate } from "../../utils";
import { Address } from "../../../assets/icons";
import "./style.scss";

const Plataform = (): JSX.Element => {
  const [selected, setSelected] = useState("");

  const data = useMemo(
    () => [
      {
        id: "student",
        title: "Ex-alunas",
        type: "Empregabilidade",
        time: "01/08/2021",
        description:
          "Acesso de ex-alunas para cadastro de perfil e currículo e acesso a vagas e oportunidades de empresas parceiras",
        functions: [
          { level: 1, key: "Cadastro" },
          { level: 3, key: "Curriculo" },
          { level: 2, key: "Certificados" },
          { level: 2, key: "Portfolios" },
          { level: 1, key: "Oportunidades" },
        ],
      },
      {
        id: "corporate",
        title: "Empresas",
        type: "Empregabilidade",
        time: "01/08/2021",
        description:
          "Acesso de empresas para cadastro de vagas e oportunidades, efetuando ou não a seleção dentro da plataforma",
        functions: [
          { level: 1, key: "Cadastro" },
          { level: 2, key: "Vagas" },
          { level: 3, key: "Seleção" },
          { level: 3, key: "Contatos" },
        ],
      },
      {
        id: "candidate",
        title: "Inscrições",
        type: "Processo seletivo",
        time: "01/01/2022",
        description:
          "Acesso de interessadas para se candidatar nos cursos e participar do processo seletivo",
        functions: [
          { level: 2, key: "Cadastro" },
          { level: 3, key: "Formulário" },
          { level: 3, key: "Vídeo" },
          { level: 2, key: "Oficina" },
        ],
      },
      {
        id: "admin",
        title: "Admin",
        type: "Interno",
        time: "01/01/2023",
        description:
          "Acesso de colaboradoras para gestão da plataforma, criação e acompanhamento de turmas e processos e execução da seleção",
        functions: [
          { level: 2, key: "Seleção" },
          { level: 3, key: "Processos" },
          { level: 1, key: "Turmas" },
          { level: 1, key: "Gestão" },
        ],
      },
    ],
    [],
  );

  const labelSkilllevel = useCallback((level) => {
    switch (level) {
      case 1:
        return "básico";
      case 2:
        return "intermediário";
      case 3:
        return "avançado";
      default:
        return "";
    }
  }, []);

  return (
    <div className="grid-column-1-13 grid-row-1">
      <div className="admin-subscriptions-infos-header-bottom margin-bottom-32">
        {data.map(({ id, title }) => (
          <button
            key={Math.random()}
            type="button"
            className={selected === id ? "is-active" : ""}
            onClick={() => setSelected(id)}
          >
            {title}
          </button>
        ))}
      </div>
      {data
        .filter((item) => item.id === selected)
        .map((item) => (
          <div key={Math.random()} className="vacancy-header">
            <Card hideBorderRadius className="margin-bottom-32">
              <div className="vacancy-header-image" />
              <h1 className="vacancy-header-title margin-top-16">
                {item.title}
              </h1>
              <h2 className="vacancy-header-company">{item.type}</h2>
              <p className="vacancy-header-date">
                {moment(item.time).isValid() && getTextDiffDate(item.time)}
              </p>
              <div className="flex space-between align-items-end">
                <div className="vacancy-header-location">
                  <Address />
                  <span className="vacancy-header-type">Remoto</span>
                </div>
              </div>
            </Card>
            <Card
              hideBorderRadius
              removeTitleBorder
              titleHeader="Sobre"
              className="vacancy-header-about padding-0"
            >
              <h3 className="title-accordion margin-left-skill">
                Funcionalidades
              </h3>
              <div className="candidature-skill-vacancy-container margin-left-skill">
                {item.functions.map((skill) => (
                  <div
                    key={Math.random()}
                    className="candidature-skill-vacancy"
                  >
                    {`${skill.key} (`}
                    <span className="italic-text">
                      {labelSkilllevel(skill.level)}
                    </span>
                    {")"}
                  </div>
                ))}
              </div>
              <p className="vacancy-header-descriptions margin-top-0">
                {item.description}
              </p>
            </Card>

            <Card
              hideBorderRadius
              removeTitleBorder
              titleHeader="Empresa"
              className="vacancy-header-about padding-0 "
            >
              <div>
                <div className="display-inline width-100">
                  <img
                    src="https://medias.app.reprograma.com.br/366362a66358b90cd774-reprograma.svg"
                    alt="Logo"
                    className="vacancy-header-logo"
                  />
                  <div className="vacancy-company">
                    <h2 className="vacancy-company-name">{"{reprograma}"}</h2>
                    <a
                      className="vacancy-company-website"
                      href="https://reprograma.com.br/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      reprograma.com.br
                    </a>
                  </div>
                </div>
                <p className="vacancy-company-description">
                  {
                    "A {reprograma} é uma iniciativa de impacto social que tem como missão diminuir a lacuna de gênero no setor de tecnologia por meio da capacitação profissional em programação de mulheres em situações de vulnerabilidade, priorizando em seus processos seletivos negras, trans e travestis."
                  }
                </p>
              </div>
            </Card>
          </div>
        ))}
    </div>
  );
};

export default memo(Plataform);
