import React, { memo, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { Action } from "redux";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { Button, Input, Modal, Select } from "../../../components";
import { setLoad } from "../../../store/actions/configurationsActions";
import { userBootcampsStatus } from "../../../types/enumerators";
import { ScholarshipType } from "../../../types/constants/User";
import { patchUser } from "../../../services/functions";
import { UserBootcamps } from "../../../types/interfaces";
import "../style.scss";

interface User {
  userId: string;
  bootcamps: UserBootcamps[];
}

interface Props {
  open: boolean;
  close: () => void;
  user: User;
  classId: string;
  reload: () => void;
}

const ToForm = ({ open, close, user, classId, reload }: Props): JSX.Element => {
  const [hasScholarship, setHasScholarship] = useState<string>();
  const [scholarshipType, setScholarshipType] =
    useState<keyof typeof ScholarshipType>();
  const [frequency, setFrequency] = useState<number>();

  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    setHasScholarship(undefined);
    setScholarshipType(undefined);
    setFrequency(undefined);
    close();
  }, [close]);

  const onChangeStatus = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);

    const bootcamps = [...(user?.bootcamps ?? [])];
    const i = bootcamps?.findIndex((item) => item?.classId === classId) ?? -1;

    if (i > -1) {
      const bootcamp = {
        ...bootcamps[i],
        status: userBootcampsStatus.FINISHED,
        hasScholarship: hasScholarship === "hasScholarship",
        scholarshipType,
        frequency,
      };

      bootcamps[i] = bootcamp;

      await patchUser(user?.userId ?? "", { bootcamps });
    }

    reload();
    onClose();
    dispatch(setLoad(false) as unknown as Action);
  }, [
    dispatch,
    user?.bootcamps,
    user?.userId,
    reload,
    onClose,
    classId,
    hasScholarship,
    scholarshipType,
    frequency,
  ]);

  return (
    <Modal headerAction open={open} onClose={onClose} textHeader="Formar aluna">
      <div>
        <p className="title-input">Tem bolsa?</p>
        <RadioGroup
          aria-label="hasScholarship"
          name="hasScholarship"
          onChange={(e) => setHasScholarship(e.target.value)}
        >
          <div>
            <FormControlLabel
              value="hasScholarship"
              control={<Radio />}
              label="Sim"
            />
            <FormControlLabel
              value="hasntScholarship"
              control={<Radio />}
              label="Não"
            />
          </div>
        </RadioGroup>
      </div>
      {hasScholarship === "hasScholarship" && (
        <Select
          label="Selecione*"
          options={Object.keys(ScholarshipType)?.map((key) => ({
            value: key,
            label: ScholarshipType[key as keyof typeof ScholarshipType],
          }))}
          value={scholarshipType ?? ""}
          onChange={(e) => setScholarshipType(e.target.value)}
          className="margin-top-32"
        />
      )}
      <Input
        label="% de frequência/entrega"
        type="number"
        value={frequency}
        onChange={(e) => setFrequency(Number(e.target.value))}
        className="margin-top-32"
      />

      <div className="margin-top-32 admin-subscriptions-modal-buttons">
        <Button
          text="Cancelar"
          onClick={onClose}
          outline
          appearance="secondary"
        />
        <Button
          text="Salvar"
          className="margin-left-32 margin-left-mobile"
          onClick={onChangeStatus}
          disabled={!frequency}
        />
      </div>
    </Modal>
  );
};

export default memo(ToForm);
