import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import moment from "moment-timezone";
import { RootState } from "../../../../store/reducers";
import { classVacancyType } from "../../../../types/enumerators";
import { updateClassVacancySubscriptionById } from "../../../../services/functions";
import {
  ClassVacancy,
  ClassVacancyStages,
  ClassVacancySubscription,
} from "../../../../types/interfaces";
import { Button, Select } from "../../../../components";
import Participant from "./participant";
import Technical from "./technical";
import Behavior from "./behavior";
import Proposes from "./proposes";
import "../../style.scss";
import FormData from "./formData";

interface Props {
  course: ClassVacancy;
  subscription: ClassVacancySubscription;
  onClose: () => void;
  reload: () => void;
  className?: string;
}

const susbtepInformations: Record<
  | "hasDiversityForm"
  | "hasEvaluationBehavior"
  | "hasTechnicalTest"
  | "hasTechnicalTestForm",
  { title: string; label: string; text: string }
> = {
  hasDiversityForm: {
    title: "Formulário de diversidade",
    label: "diversity",
    text: "Nota de diversidade",
  },
  hasEvaluationBehavior: {
    title: "Comportamental",
    label: "behavior",
    text: "Nota comportamental",
  },
  hasTechnicalTest: {
    title: "Desafio técnico",
    label: "technical",
    text: "Nota técnica",
  },
  hasTechnicalTestForm: {
    title: "Desafio técnico",
    label: "technical",
    text: "Nota técnica",
  },
};

const Edit = ({
  className,
  course,
  subscription,
  onClose,
  reload,
}: Props): JSX.Element => {
  const [menu, setMenu] = useState<string>("");
  const [grade, setGrade] = useState<{ type?: string; value?: number }[]>();
  const [participant, setParticipant] = useState<{
    morning?: boolean;
    afternoon?: boolean;
  }>();

  const stageWorkshopCourse = useMemo(
    () =>
      course?.stages?.find((item) => item?.type === classVacancyType.WORKSHOP),
    [course?.stages],
  );

  const stageWorkshopSubscription = useMemo(
    () =>
      subscription?.stages?.find(
        (stage) => stage?.stageId === stageWorkshopCourse?.stage_id,
      ),
    [subscription?.stages, stageWorkshopCourse?.stage_id],
  );

  useEffect(() => {
    if (stageWorkshopSubscription?.grade && !grade)
      setGrade(stageWorkshopSubscription?.grade);
  }, [grade, stageWorkshopSubscription?.grade]);

  useEffect(() => {
    if (stageWorkshopSubscription?.participant && !participant)
      setParticipant(stageWorkshopSubscription?.participant);
  }, [participant, stageWorkshopSubscription?.participant]);

  const userLogged = useSelector((state: RootState) => state.userState);

  const indexStageWorkshopSubscription = useMemo(
    () =>
      subscription?.stages?.findIndex(
        (stage) => stage?.stageId === stageWorkshopCourse?.stage_id,
      ),
    [subscription?.stages, stageWorkshopCourse?.stage_id],
  );

  const menuOptions = useMemo(() => {
    if (subscription && course && stageWorkshopCourse) {
      const newList = (Object.entries(susbtepInformations)
        ?.map(
          ([prop, value]) =>
            Object.hasOwn(stageWorkshopCourse, prop) &&
            !!stageWorkshopCourse[prop as keyof ClassVacancyStages] && {
              type: prop,
              ...(value as object),
            },
        )
        ?.filter((item) => item !== false) ?? []) as {
        type?: string;
        title?: string;
        label?: string;
        text?: string;
      }[];

      return newList;
    }

    return [];
  }, [course, subscription, stageWorkshopCourse]);

  useEffect(() => {
    if (stageWorkshopSubscription?.grade && !grade)
      setGrade(stageWorkshopSubscription?.grade);
  }, [grade, stageWorkshopSubscription?.grade]);

  useEffect(() => {
    if (stageWorkshopSubscription?.participant && !participant)
      setParticipant(stageWorkshopSubscription?.participant);
  }, [participant, stageWorkshopSubscription?.participant]);

  const finalNote = useCallback((...gradesValue) => {
    let sum = 0;
    for (let i = 0; i < gradesValue?.length; i += 1) {
      if (gradesValue[i]) {
        sum += Number(gradesValue[i]);
      }
    }
    return sum;
  }, []);

  const calcFinal = useCallback(
    (data: { type?: string; value?: number }[]) => {
      let final;
      const newGrade = [...data];

      const diversity = newGrade?.find(
        (item: { type?: string }) => item?.type === "DIVERSITY",
      )?.value;
      const technical = newGrade?.find(
        (item: { type?: string }) => item?.type === "TECHNICAL",
      )?.value;
      const behavior = newGrade?.find(
        (item: { type?: string }) => item?.type === "BEHAVIOR",
      )?.value;

      if (
        (menuOptions?.find(
          (item) =>
            item?.type === "hasTechnicalTest" ||
            item?.type === "hasTechnicalTestForm",
        ) &&
          Number(technical) < 4) ||
        (menuOptions?.find((item) => item.type === "hasDiversityForm") &&
          Number(diversity) < 4) ||
        (menuOptions?.find((item) => item?.type === "hasEvaluationBehavior") &&
          Number(behavior) < 3)
      )
        final = 0;
      else
        final =
          finalNote(diversity, technical, behavior) /
          Number(menuOptions?.length);

      const finalFormated = Number(parseFloat(Number(final)?.toFixed(2)));

      const findIndexFinal = newGrade?.findIndex(
        (item) => item?.type === "FINAL",
      );

      if (findIndexFinal !== -1) newGrade[findIndexFinal].value = finalFormated;
      else {
        newGrade?.push({
          type: "FINAL",
          value: finalFormated,
        });
      }

      setGrade(newGrade);
    },
    [finalNote, menuOptions],
  );

  const submit = useCallback(async () => {
    const userIds = [
      ...(stageWorkshopSubscription?.userIds?.filter(
        (item: string) => item !== userLogged?._id,
      ) ?? []),
      userLogged?._id,
    ];

    const stages = subscription?.stages?.map((general, idx) =>
      idx === indexStageWorkshopSubscription
        ? {
            ...subscription?.stages[idx],
            userIds,
            date: moment().tz("America/Sao_Paulo").format("DD/MM/YYYY"),
            participant,
            grade,
          }
        : general,
    );

    if (subscription && stages?.length)
      await updateClassVacancySubscriptionById(subscription?._id ?? "", {
        stages,
      });

    setMenu("");
    reload();
  }, [
    stageWorkshopSubscription?.userIds,
    userLogged?._id,
    subscription,
    reload,
    indexStageWorkshopSubscription,
    participant,
    grade,
  ]);

  if (!subscription || !subscription?.stages)
    return (
      <div className="subscriptions-loading subscriptions-loading-propose">
        <CircularProgress />
      </div>
    );

  return (
    <>
      <div className={`propose-form WORKSHOP ${className ?? ""}`}>
        <div className="propose-form-steps display-flex">
          <Select
            value={menu}
            label="Selecione uma subetapa"
            options={menuOptions?.map(({ type = "", title = "" }) => ({
              value: type,
              label: title,
            }))}
            onChange={(e) => setMenu(e.target.value)}
          />
        </div>

        {menu && (
          <>
            <Participant
              participant={participant}
              setParticipant={setParticipant}
            />

            {menu === "hasDiversityForm" && (
              <FormData
                form={stageWorkshopSubscription?.form}
                title="Nota de diversidade"
              />
            )}

            {menu === "hasTechnicalTestForm" && (
              <FormData
                form={stageWorkshopSubscription?.technicalForm}
                title="Desafio técnico"
              />
            )}

            {menu === "hasTechnicalTest" && (
              <Technical media={stageWorkshopSubscription?.media} />
            )}

            {menu === "hasEvaluationBehavior" && (
              <Behavior
                grade={grade}
                calcFinal={calcFinal}
                behavior={stageWorkshopSubscription?.behavior}
              />
            )}

            <Proposes grade={grade} steps={menuOptions} calcFinal={calcFinal} />
          </>
        )}
      </div>
      <div className={`admin-subscriptions-modal-buttons ${className ?? ""}`}>
        <Button
          text="Cancelar"
          onClick={onClose}
          outline
          appearance="secondary"
        />
        <Button text="Salvar" onClick={submit} />
      </div>
    </>
  );
};

export default Edit;
