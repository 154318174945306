/* eslint-disable no-prototype-builtins */
import React, { memo, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Action } from "redux";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { CardRegister, Button } from "../../../components";
import { formClassVacancy } from "./data";
import {
  ClassVacancyStages,
  ClassVacancySubscription,
  ClassVacancySubscriptionStage,
  User,
} from "../../../types/interfaces";
import {
  getClassById,
  updateClassVacancySubscriptionById,
} from "../../../services/functions";
import { CourseSubscriptionAction } from "../../../types/enumerators/actions/CourseSubscriptionAction";
import { CourseSubscriptionState } from "../../../types/constants/Historic";
import { createCourseSubscriptionLog } from "../../../services/historicFunctions";
import { setLoad } from "../../../store/actions/configurationsActions";

interface Props {
  userId: User["_id"];
  subscription: ClassVacancySubscription;
  findStageWorkshop: ClassVacancyStages;
  currentStageWorkshop: ClassVacancySubscriptionStage;
  prop: "form" | "technicalForm";
  text: string;
}

interface FormValues {
  type: string[];
}

export type Form = Array<FormValues>;

const dictionaryPropsForm: {
  [key: string]: { log: "FORM_DIVERSITY" | "TECHNICAL_FORM"; grade: string };
} = {
  form: {
    log: "FORM_DIVERSITY",
    grade: "DIVERSITY",
  },
  technicalForm: {
    log: "TECHNICAL_FORM",
    grade: "TECHNICAL",
  },
};

const Form = ({
  userId,
  subscription,
  findStageWorkshop,
  currentStageWorkshop,
  prop,
  text,
}: Props): JSX.Element => {
  const [form, setForm] = useState<Form>([]);

  const actionLog:
    | "COURSE_SUBSCRIPTION_WORKSHOP_FORM_DIVERSITY_SUBMITTED"
    | "COURSE_SUBSCRIPTION_WORKSHOP_TECHNICAL_FORM_SUBMITTED" = `COURSE_SUBSCRIPTION_WORKSHOP_${dictionaryPropsForm[prop].log}_SUBMITTED`;
  const logSubmission:
    | "WORKSHOP_FORM_DIVERSITY_SUBMISSION"
    | "WORKSHOP_TECHNICAL_FORM_SUBMISSION" = `WORKSHOP_${dictionaryPropsForm[prop].log}_SUBMISSION`;
  const logSubmited:
    | "WORKSHOP_FORM_DIVERSITY_SUBMITTED"
    | "WORKSHOP_TECHNICAL_FORM_SUBMITTED" = `WORKSHOP_${dictionaryPropsForm[prop].log}_SUBMITTED`;

  const handleChangeValue = useCallback(
    (value, props) => {
      setForm({ ...form, [props]: value });
    },
    [form],
  );

  const calcGrade = useCallback(() => {
    const grade =
      subscription?.stages?.find(
        (item) => item?.stageId === findStageWorkshop?.stage_id,
      )?.grade ?? [];

    const indexProp =
      grade?.findIndex(
        (item) => item?.type === dictionaryPropsForm[prop].grade,
      ) ?? -1;
    const indexFinal = grade?.findIndex((item) => item?.type === "FINAL") ?? -1;

    const allQuestions = formClassVacancy
      ?.filter((item) => !!item.key)
      ?.map((question) => ({
        key: question?.key,
        rightAnswer: question?.rightAnswer,
        value: question?.value,
      }));

    let returnObject: { resultAnswer?: boolean; value?: number } = {};

    const results = Object.entries(form).map(
      // eslint-disable-next-line array-callback-return, consistent-return
      ([key, values]) => {
        if (Array.isArray(values)) {
          for (let i = 0; i < allQuestions?.length; i += 1) {
            if (
              key === allQuestions[i]?.key &&
              Array.isArray(allQuestions[i]?.rightAnswer)
            ) {
              const rightAnswerItem = allQuestions[i]?.rightAnswer?.sort();
              const val = values.sort();

              const includedAnswer = (currentValue: string) =>
                rightAnswerItem?.includes(currentValue);

              const result = val.every(includedAnswer);

              returnObject = {
                resultAnswer: result,
                value: allQuestions[i].value,
              };
            }
          }
          return returnObject;
        }
      },
    );

    let sumQuestions = 0;
    let sumQuestionsWithValue = 0;
    for (let i = 0; i <= results.length; i += 1) {
      const question = results[i];
      if (question && question?.resultAnswer) {
        if (question?.value) sumQuestionsWithValue += question.value;
        else sumQuestions += 1;
      }
    }

    const value = Number(sumQuestions + sumQuestionsWithValue) ?? 0;
    if (indexProp > -1) grade[indexProp].value = value;
    else
      grade?.push({
        type: dictionaryPropsForm[prop].grade,
        value,
      });

    const technical = grade?.find(
      (item: { type?: string }) => item?.type === "TECHNICAL",
    )?.value;
    const behavior = grade?.find(
      (item: { type?: string }) => item?.type === "BEHAVIOR",
    )?.value;

    let final =
      (Number(value ?? 0) + Number(technical ?? 0) + Number(behavior ?? 0)) / 3;

    if (
      ((findStageWorkshop?.hasTechnicalTest ||
        findStageWorkshop?.hasTechnicalTestForm) &&
        Number(technical) < 4) ||
      (findStageWorkshop?.hasEvaluationBehavior && Number(behavior) < 3)
    )
      final = 0;

    if (indexFinal > -1)
      grade[indexFinal].value = Number(parseFloat(Number(final).toFixed(2)));
    else
      grade?.push({
        type: "FINAL",
        value: Number(parseFloat(Number(final).toFixed(2))),
      });

    return grade;
  }, [
    findStageWorkshop?.hasEvaluationBehavior,
    findStageWorkshop?.hasTechnicalTest,
    findStageWorkshop?.hasTechnicalTestForm,
    findStageWorkshop?.stage_id,
    form,
    prop,
    subscription?.stages,
  ]);

  const dispatch = useDispatch();
  const onSave = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    const response = await getClassById(
      currentStageWorkshop?.workshopClass ?? "",
    );

    const workshop = response?.data;

    const finishedStatus = workshop?.status === "FINISHED";

    if (!finishedStatus && userId) {
      if (form) {
        const grade = calcGrade();
        const stages = subscription?.stages?.map((item) => {
          if (item?.stageId === findStageWorkshop?.stage_id)
            return {
              ...item,
              [prop]: form,
              grade,
            };

          return {
            ...item,
          };
        });

        createCourseSubscriptionLog(
          userId,
          CourseSubscriptionAction?.[actionLog],
          CourseSubscriptionState?.[logSubmission],
          CourseSubscriptionState?.[logSubmited],
        );

        await updateClassVacancySubscriptionById(subscription?._id ?? "", {
          stages,
        });
      }
      dispatch(setLoad(true) as unknown as Action);
    }
    window.location.reload();
  }, [
    dispatch,
    currentStageWorkshop?.workshopClass,
    userId,
    form,
    calcGrade,
    subscription?.stages,
    subscription?._id,
    actionLog,
    logSubmission,
    logSubmited,
    findStageWorkshop?.stage_id,
    prop,
  ]);

  return (
    <>
      <CardRegister
        titleHeader={text}
        className="margin-top-16 margin-mobile-register border-card grid-column-5-12 grid-column-1-11-mobile course-form"
      >
        <div className="information">
          {formClassVacancy?.map((item) => (
            <>
              <p className="information-title">{item?.label}</p>

              {item?.description && (
                <div className="sub-title-input description">
                  {item?.description}
                </div>
              )}

              {item?.subtitle && (
                <div className="subtitle-form">{item?.subtitle}</div>
              )}

              {item?.rightAnswer && item?.rightAnswer?.length <= 1 && (
                <RadioGroup className="radio-group" key={Math.random()}>
                  {item?.options?.map(({ index, label }) => (
                    <FormControlLabel
                      key={Math.random()}
                      value={index}
                      control={<Radio />}
                      label={label}
                      checked={form[item?.key as keyof typeof form]?.includes(
                        index,
                      )}
                      onChange={(e) =>
                        handleChangeValue([e.target.value], item?.key)
                      }
                    />
                  ))}
                </RadioGroup>
              )}
            </>
          ))}
        </div>
      </CardRegister>
      <Button
        text="Enviar"
        className="width-100 grid-column-1-11-mobile grid-row-2 grid-column-10-12 margin-bottom-48 button-course"
        onClick={onSave}
        disabled={!Object.values(form).every((item) => item?.length !== 0)}
      />
    </>
  );
};
export default memo(Form);
